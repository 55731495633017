import React from 'react';

const IconLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="85.000000pt"
    height="85.000000pt"
    viewBox="0 0 1271.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>LOGO</metadata>
    <g
      transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
      fill="#ffae57"
      stroke="none">
      <path
        d="M8325 10515 c-244 -49 -413 -91 -567 -141 -267 -86 -376 -153 -591
-364 -249 -242 -391 -408 -968 -1130 -936 -1172 -1569 -2015 -2101 -2801
l-182 -269 -401 6 c-283 5 -428 3 -495 -5 -492 -61 -809 -262 -995 -631 -48
-96 -102 -247 -91 -257 4 -4 36 7 72 25 35 18 66 32 68 32 3 0 -6 -28 -20 -61
-26 -67 -40 -129 -30 -129 4 0 46 18 93 41 271 129 638 200 1118 216 202 6
207 6 197 -13 -5 -10 -55 -93 -109 -184 -547 -909 -838 -1618 -860 -2090 -6
-144 9 -229 55 -294 15 -21 42 -64 60 -95 46 -78 75 -105 145 -138 60 -27 67
-28 212 -27 114 1 168 6 228 21 128 33 707 247 707 261 0 7 -16 36 -35 65 -19
28 -35 57 -35 63 0 6 -11 31 -24 55 -37 68 -49 148 -43 279 18 326 135 728
363 1237 69 155 335 684 380 755 l26 41 237 -6 c130 -4 542 -10 916 -13 805
-8 1199 6 1789 62 112 10 161 11 166 4 4 -6 12 -48 19 -93 22 -149 139 -719
186 -907 138 -556 292 -973 465 -1260 244 -406 416 -555 655 -568 227 -12 468
111 788 401 48 43 87 83 87 87 0 4 -25 27 -56 50 -51 39 -54 43 -40 59 26 28
19 44 -32 76 -76 47 -231 210 -304 320 -204 306 -331 704 -508 1595 -202 1012
-316 1928 -372 3000 -19 368 -17 1255 5 1530 21 270 58 611 93 850 37 250 38
235 -15 234 l-43 -1 7 73 c3 39 3 74 -2 77 -4 2 -102 -15 -218 -38z m-1055
-1823 c0 -625 66 -1491 192 -2534 23 -191 40 -350 37 -353 -4 -3 -73 -12 -155
-20 -473 -46 -1112 -62 -1829 -46 -302 7 -552 14 -554 16 -6 7 175 338 284
521 387 646 792 1198 1616 2204 151 184 301 369 334 411 33 42 63 74 68 72 4
-2 7 -124 7 -271z"
      />
    </g>
  </svg>
);

export default IconLogo;
