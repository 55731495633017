module.exports = {
  siteTitle: 'Abo Baloyi | Full Stack Developer',
  siteDescription: 'Am ABO I build websites, applications, interactive systems a freelancer based in johannesburg  ',
  siteKeywords:
    'ABO Baloyi,Abo baloyi, software engineer, web developer,Full stack developer, javascript, south Africa',
  siteUrl: 'https://baloyiabo.co.za/',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-162529915-1',
  googlesiteverification: 'rv_zXy5U_tH4QOPcuyCZ4wC1EaZyyPqFw1kXp7tBRek',
  name: 'Abo Baloyi',
  location: 'Joburg, SA',
  email: 'abo@baloyiabo.co.za',
  github: 'https://github.com/ABOBALOYI',
  twitterHandle: '@abobaloyi',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/ABOBALOYI',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/abo-baloyi-54b353122/',
    },
    {
      name: 'Stackoverflow',
      url: 'https://stackoverflow.com/users/8611704/abo-baloyi',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/abobaloyi/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/ABOBALOYI',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
    {
      name: 'Blog',
      url: '/blog',
    },
  ],

  navHeight: 100,
  greenColor: '#ffae57 ',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
